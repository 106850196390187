<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Language Key</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add New Language kEy'" @click="languageKeyModals.addKey = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Language Key list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allLangagekeyData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allLangagekeyData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading languages data. Please wait...
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allLangagekeyData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allLangagekeyData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allLangagekeyData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllLanguageKeys(allLangagekeyData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allLangagekeyData.search" placeholder="Search Language Key" @keyup.enter="fetchAllLanguageKeys(allLangagekeyData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allLangagekeyData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allLangagekeyData.dateRange[1] != null ? fetchAllLanguageKeys(allLangagekeyData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allLangagekeyData.search !== null || allLangagekeyData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllLanguageKeys(allLangagekeyData.activityStatus, 'resetFilters')"/>
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType" @click="exportXL('excel')"></SplitButton>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Language Key found...
                </template>
                <Column field="keyName" header="Key Name" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{slotProps.data.keyName}}</strong>
                    </template>
                </Column>

                <Column field="keyValue" header="Key Value" :sortable="true"></Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['language_key_status ', slotProps.data.status ? 'language_key_status_success' : 'language_key_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editLanguageKey(slotProps.data)" v-tooltip="'Edit Language'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Language'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Language'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language Key list End --->
        </div>
        <!--- Add Language Key Modal Start --->
        <Dialog header="Add New Key" v-model:visible="languageKeyModals.addKey" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_language_add" @submit.prevent="createLanguageKey()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="key_name">Key Name*</label>
                        <InputText v-model="addEditLanguageKey.name" placeholder="E.g. ReadingLine" id="key_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.nameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_value">Key Value*</label>
                        <InputText v-model="addEditLanguageKey.value" placeholder="E.g. Reading Line" id="key_value" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.valueEmpty">Value is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditLanguageKey.addStatus === 200 ? 'success' : 'error']" v-if="addEditLanguageKey.addStatus !== ''">{{addEditLanguageKey.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Language Key Modal End --->
        <!--- Edit Language Key Modal Start --->
        <Dialog header="Edit New Key" v-model:visible="languageKeyModals.editKey" :modal="true" :style="{width: '600px'}" @hide="close()">
            <form class="sup_language_add" @submit.prevent="updateLanguage(languageKeyModals.editData)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="key_name">Key Name*</label>
                        <InputText v-model="addEditLanguageKey.name" placeholder="E.g. ReadingLine" id="key_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.nameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="key_value">Key Value*</label>
                        <InputText v-model="addEditLanguageKey.value" placeholder="E.g. Reading Line" id="key_value" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.valueEmpty">Value is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditLanguageKey.editStatus === 200 ? 'success' : 'error']" v-if="addEditLanguageKey.editStatus !== ''">{{addEditLanguageKey.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Language Key Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreLanguagekey(confirmationModal.modaldata.data, confirmationModal.modaldata.type), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allLanguageKeyList, languageKeyById, allLanguageTextList, languageExport } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { usFormatDate, formatDate } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');

export default {
    name: "LanguageKey",
    setup(){
        //Store call
        let store = useStore();

        const allLangagekeyData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            fetch: false
        });

        //Langage modals open-close state 
        const languageKeyModals = reactive ({
            addKey: false,
            editKey: false,
            editData: null
        });

        //All from input and message data binding state
        const addEditLanguageKey = reactive ({
            name: '',
            value: '',
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: ''
        });

        //All from error handling state
        const addEditvalidationError = reactive ({
            nameEmpty: false,
            valueEmpty: false,
            voiceSupportEmpty: false,
            flagEmpty: false
        });

        //Active-deactive confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //Export Language type
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        //On before mount function invoke
        onBeforeMount(async()=>{
            fetchAllLanguageKeys();
        })

        //Fetch All Language Key
        const fetchAllLanguageKeys = async (activityStatus, load) =>{
            let date = '';
            if(load === "languageStatus"){
                allLangagekeyData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active';
            }

            if(load === "resetFilters"){
                allLangagekeyData.search = null;
                allLangagekeyData.dateRange = null;
            }

            if (load === 'dateRange') {
                date = formatDate(allLangagekeyData.dateRange[0]) + ' ' + formatDate(allLangagekeyData.dateRange[1]);
            }

            try{
                allLangagekeyData.loading = !allLangagekeyData.loading;
                const response = await axios.get(allLanguageKeyList, {
                    params: {
                        status: allLangagekeyData.activityStatus,
                        search: allLangagekeyData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                if(response.data.status === 200){
                    allLangagekeyData.fetch = true;
                    allLangagekeyData.data = response.data.data.languageKey;
                    allLangagekeyData.loading = !allLangagekeyData.loading;
                    autoTranslate();
                }
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //New langauge create HTTP call
        const createLanguageKey = async() => {
            let isValidate = languagekeyFormValidation();

            if(isValidate){
                try{
                    const response = await axios.post(allLanguageKeyList, {
                        keyName: addEditLanguageKey.name,
                        keyValue: addEditLanguageKey.value,
                    },{
                        headers:  {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }
                    });
                    addEditLanguageKey.addStatus = response.data.status;
                    addEditLanguageKey.addMessage = response.data.message;
                    resetForm();
                    fetchAllLanguageKeys();
                } catch(err){
                    console.log(err);
                }
            }
        }

        //Sync language auto translate
        const autoTranslate = async() =>{
            let uuid = [];
            if(allLangagekeyData.fetch === true){
                for(let i=0; i< allLangagekeyData.data.length; i++){
                    uuid.push(allLangagekeyData.data[i].uuid)
                }
                const res = await axios.post(allLanguageTextList,{
                    text: uuid
                },{
                    headers:  {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                console.log(res);
            }
            
        }

        //Add-edit language form validation
        const languagekeyFormValidation = () => {
            if(addEditLanguageKey.name === ""){
                addEditvalidationError.nameEmpty = true;
            } else {
                addEditvalidationError.nameEmpty = false;
            }

            if(addEditLanguageKey.value === ""){
                addEditvalidationError.valueEmpty = true;
            } else {
                addEditvalidationError.valueEmpty = false;
            }

            if(addEditvalidationError.nameEmpty && addEditvalidationError.codeEmpty) {
                return false;
            } else {
                return true;
            }
        }

        //Reset form
        const resetForm = () => {
            addEditLanguageKey.name = '';
            addEditLanguageKey.value = '';
        }

        //All popup close sate reset
        const close = () => {
            resetForm();
            addEditLanguageKey.addStatus= '';
            addEditLanguageKey.addMessage= '';
            addEditLanguageKey.editStatus= '';
            addEditLanguageKey.editMessage= '';
        }

        //Edit language key popup open and input data bind
        const editLanguageKey = async(slotData) => {
            const response = await axios.get( languageKeyById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if(response.data.status === 200 ){
                languageKeyModals.editKey = true;
                languageKeyModals.editData = slotData.uuid
                addEditLanguageKey.name = response.data.data.keyName;
                addEditLanguageKey.value = response.data.data.keyValue;
            }
        }

        //langage update HTTP call
        const updateLanguage = async(uuid) => {
            try{
                const options = {
                    method: 'put',
                    url: allLanguageKeyList,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        keyName: addEditLanguageKey.name,
                        keyValue: addEditLanguageKey.value,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                addEditLanguageKey.editStatus = response.data.status;
                addEditLanguageKey.editMessage = response.data.message;
                fetchAllLanguageKeys('active');
            } catch(err){
                console.log('err', err);
                // allCustomerData.editStatus = err.response.data.status;
                // allCustomerData.editMessage = err.response.data.message;
            }
        }

        //Delete-restore language confirmation modal
        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                type: load
            }
        }
        
        //Delete-restore language HTTP call
        const deleteAndRestoreLanguagekey = async (slotData, methodType) => {
            try{
                const options = {
                    method: methodType === 'delete' ? 'delete' : 'patch',
                    url: allLanguageKeyList,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllLanguageKeys('active');
            } catch(err){
                console.log(err);
            }
        }

        //Export Language list
        const exportXL = async (load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    if (allLangagekeyData.dateRange !== null) {
                        date = formatDate(allLangagekeyData.dateRange[0]) + ' ' + formatDate(allLangagekeyData.dateRange[1]);
                    }
                    const response = await axios.get( languageExport, {
                        responseType: 'arraybuffer',
                        params: {
                            exe: "xlsx",
                            print: false,
                            status: allLangagekeyData.activityStatus,
                            search: allLangagekeyData.search,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        return{
            exportType,
            allLangagekeyData,
            languageKeyModals,
            addEditLanguageKey,
            addEditvalidationError,
            confirmationModal,
            fetchAllLanguageKeys,
            usFormatDate,
            createLanguageKey,
            close,
            editLanguageKey,
            updateLanguage,
            confirmationModalfn,
            deleteAndRestoreLanguagekey
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/LanguageKey';
</style>